<template>
  <div>
    <Banner v-if="bannerImages.length >0" :imageList="bannerImages" type="search-result" />
    <SearchTabs />
    <InforStrip :count="searchResultCount" :destination="isMultiSearchDate ? destinationCityName(firstDest) : destinationCityName(activeDestDates)" :date="dateString"/>

    <MobileFilter
      :sortButtonList="mobileSortButtonList"
      @updateSort="doSort"
      @filterChange="filterByOption"
      :filterOptions="filterOptions"
      isHome="searchResult"
    />
    <SearchPopup />

    <div class="my-5 sportspage_area" :class="isRTLPage ? 'right-align' : 'left-align'">
      <div class="container">
        <div class="row">
          <div class="col-lg-3 col-12 sideBar desktop_form">
            <filter-panel
              v-if="!isLoading"
              :options="filterOptions"
              @change="filterByOption"
            />
          </div>
          <div class="col-lg-9 col-12 filter-cont package-organize" v-if="isLoadingMulti && !timerEnd">
            <h3 class="text-center">{{ $t("search-result.just-another-moment") }}</h3>
            <div class="content text-center">
              <h3>{{ $t("search-result.domestic-search.search-loading-text2", {destinationName: destinationCityName(firstDest)})}}</h3>
            </div>
            <div class="circle" style="display: flex; justify-content: center; margin : auto">{{countDown}}</div>
            <h3 class="text-center">{{ $t("search-result.seconds") }}</h3>
            <ContentLoading type="search-result"/>
          </div>
          <div class="col-lg-9 col-12 filter-cont package-organize" v-else-if="isLoadingMulti && timerEnd">
            <div class="content text-center">
              <h3>{{ $t("search-result.domestic-search.search-loading-time-end", {
                destinationName: isMultiSearchDate ? destinationCityName(firstDest) : destinationCityName(activeDestDates)
              })}}</h3>
            </div>
            <ContentLoading type="search-result"/>
          </div>

          <div class="col-lg-9 col-12 filter-cont package-organize" v-else>
            <div class="desktop_form my-3">
              <h3>
                {{ $t("search-result.goa") }}: {{ searchResultCount }}
                {{ $t("search-result.properties-found") }} : {{
                  isMultiSearchDate ? destinationCityName(firstDest) : destinationCityName(activeDestDates)
                }}, {{ dateString }}
              </h3>
              <hr />
              <SortButtons :buttonList="sortButtonList" @updateSort="doSort" :chainList="hotelChainListOption.subItems"/>
            </div>
           <b-tabs v-model="tabIndex" :class="!isMultiSearchCase && 'none-multiple-search'">
              <b-tab class="my-3" v-for="(dateDest, index) in (isMultiSearchDate ? listDateKeys : listDests)"
                :key="index" :active="index===tabIndex"
                @click=" ev => changeTab(index)" >
                <template #title>
                  <div class="tab-title"><p>{{ isMultiSearchDate ? dateDest : destinationCityName(dateDest) }}</p></div>
                </template>
                <b-card-text>

                  <FilterItem v-for="(item, inx) in filteredHotel" :key="inx" :hotel="item" :itemIndex="inx" :sortedReset="sortedReset"/>
                </b-card-text>
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>
    </div>
    <Footer page="search" v-if="!hideFooter"/>
  </div>
</template>

<script>
import { BTabs, BTab, BCardText } from 'bootstrap-vue';
import TemplateSearchResult from './TemplateSearchResult';

export default {
  name: 'SearchResultTheme0',
  mixins: [TemplateSearchResult],
  components: {
    BTabs,
    BTab,
    BCardText,
    Banner: () => import('@/components/content/Banner'),
    SearchTabs: () => import('@/components/search/searchTabs/SearchTabsTheme0'),
    InforStrip: () => import('@/components/searchResult/InforStrip'),
    // SidebarFilterSlide: () => import('@/components/searchResult/atoms/SidebarFilterSlide'),
    FilterPanel: () => import('@/components/searchResult/atoms/FilterPanel'),
    MobileFilter: () => import('@/components/searchResult/MobileFilter'),
    SearchPopup: () => import('@/components/searchResult/SearchPopup'),
    // SearchTab: () => import('@/components/search/SearchTab'),
    FilterItem: () => import('@/components/searchResult/atoms/filterItem/FilterItemTheme0'),
    SortButtons: () => import('@/components/searchResult/atoms/SortButtons'),
    ContentLoading: () => import('@/components/atoms/ContentLoading'),
    Footer: () => import('@/components/content/footer/FooterTheme0'),
  },
};
</script>

<style scoped>
.tab-title {
  color: black;
  font-weight: 900;
  font-size: large;
}
a.active .tab-title {
  color: white;
}
.sportspage_area {
  direction: rtl !important;
}
.left-align .package-organize {
  direction: ltr;
  text-align: left;
}
@media (max-width: 479px) {
  .sportspage_area {
    margin: 0 !important;
  }
}
</style>

<style>
.filter-hotel .custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  right: -0.5rem;
}

.dVacation-pack.filter-cont .filterItem:not(:first-child) {
  margin-top: 0px;
}

.modal.show .modal-dialog.noSearchResult {
  display: inline-block;
  top: calc(50vh - 100px);
}

.modal-dialog.noSearchResult .modal-body{
  text-align: center;
}

.content {
  font-size:  20px;
}

.circle {
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
  font-size: 32px;
  color: #fff;
  text-align: center;
  background: red;
}

.package-organize .tab-content {
  border: none;
}

.package-organize .nav-tabs .nav-link.active {
  color: #fff;
  background-color: #005fa8;
}

.none-multiple-search ul.nav.nav-tabs {
  display: none;
}
@media (max-width: 479px) {
  .modal.show .modal-dialog.noSearchResult {
    display: flex;
    left: -10px;
    top: unset;
  }

  .content h3 {
    font-size: 20px !important;
  }

  .circle {
    width: 30px;
    height: 30px;
    line-height: 30px;
    border-radius: 50%;
    font-size: 20px;
    color: #fff;
    text-align: center;
    background: red;
  }
}
</style>
